var sw = sw || {};


// Open new window with live stream
//-----------------------------------------------------------------
sw.livestreamWindow = function() {
    var $el = $('.js_openLiveStream');

    if (!$el.length) {
        return;
    }


    var
    w       = 770,
    h       = 600,
    l       = (screen.availWidth - w) / 2,
    t       = (screen.availHeight - h) / 2;

    $el.on('click', function(event) {
        event.preventDefault;
        window.open(this.href, "window", "width= "+ w + ", height="+h+", left="+l+", top="+t+",  scrollbars=yes, location=no, toolbar=no, menubar=no, status=no");
         return false;
    });




};


$(document).ready(function (){
    sw.livestreamWindow();
});
