var sw = sw || {};

// When ready do stuff
//----------------------------------------------------------
$(document).ready(function(){
    // Check if browser can handle SVG
    if(!Modernizr.svg){
        // Get all img tag of the document and create variables
        var i=document.getElementsByTagName('img'),j,y;

        // For each img tag
        for(j = i.length ; j-- ; ){
            y = i[j].src
            // If filenames ends with SVG
            if( y.match(/svg$/) ){
                // Replace "svg" by "png"
                i[j].src = y.slice(0,-3) + 'png'
            }
        }
    }
});


// Function for resizing with timeout
//----------------------------------------------------------
$(window).resize(function() {
    if(this.resizeTo) clearTimeout(this.resizeTo);
    this.resizeTo = setTimeout(function() {
        $(this).trigger('resizeEnd');
    }, 200);
});

//every function here inits after resizing window
$(window).bind('resizeEnd', function() {
        //code to do after window is resized

        //check if need to load articles on resize (doesn't load on mobile/tablet)
        // sw.loadArticles();
});